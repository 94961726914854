import check from "../assets/orange_check.svg";
import { AiOutlineTag } from "react-icons/ai";
import { FaRegArrowAltCircleRight } from "react-icons/fa";


export const navLinks = [
  {
    name: "Features",
    link: "/#features",
  },
  {
    name: "How it works",
    link: "/#how-it-works",
  },
  {
    name: "FAQs",
    link: "/#faqs",
  },
  {
    name: "Blogs",
    link: "http://medium.com/@Credenceapp",
  },
];

export const authLinks = [
  {
    name: "sign in",
    link: "/auth/login",
  },
  {
    name: "create account",
    link: "/auth/signup",
  },
];

export const experienceItemListLeft = [
  {
    icon: check,
    text: "Reliability",
  },
  {
    icon: check,
    text: "Transparency",
  },
  {
    icon: check,
    text: "Secured Payment",
  },
];

export const experienceItemsListRight = [
  {
    icon: check,
    text: "Ease of Use",
  },
  {
    icon: check,
    text: "Swift Support",
  },
  {
    icon: check,
    text: "Scam Protection",
  },
];


export const credenceOffers = [
  {
    title: 'Access to Capital',
    textt: "Secure flexible financing solutions tailored to your agribusiness needs",
    learn: 'Learn more',
    icon: FaRegArrowAltCircleRight
  },
  {
    title: 'Marketplace',
    textt: "Open up to new opportunities by connecting to a wide network of verified buyers and sellers",
    learn: 'Learn more',
    icon: FaRegArrowAltCircleRight
  },
  {
    title: 'Escrow',
    textt: "Ensure secure delivery of your commodities and guaranteed payment with our transparent and efficient escrow service.",
    learn: 'Learn more',
    icon: FaRegArrowAltCircleRight
  },
]

export const faqs = [
  {
    question: 'What types of agribusinesses does Credence support?',
    answer: "Credence is dedicated to assisting a wide range of agribusinesses, with a primary focus on grain commodities. Whether you're a farmer or Agri-SME merchant– be it a Rice Trader in Kaduna or a Cocoa trader in Ondo – Credence tailors solutions to meet your specific requirements."
  },
  {
    question: 'How does Credence ensure the security of my transactions?',
    answer: 'Ensuring the security of your transactions is our top priority. We leverage advanced technology to safeguard your data and transactions. Additionally, Credence offers escrow services to further minimize risk, fostering trust between buyers and sellers.'
  },
  {
    question: 'How can I find buyers and sellers through Credence?',
    answer: 'Credence provides a dynamic platform connecting you with a diverse marketplace of buyers and sellers for various agricultural commodities. Utilize our platform to create profiles, browse listings, and engage in secure negotiations directly with other users.'
  },
  {
    question: 'What are the fees associated with using Credence?',
    answer: 'Credence operates with transparent and competitive pricing across all our services. The specific fees depend on the type of solution you choose and your individual needs.'
  },
  {
    question: 'How can I get started with Credence?',
    answer: 'Getting started with Credence is easy. Simply create a free account on our website by clicking the "Create Account" button. Explore our offerings, delve into details about our solutions, and feel free to reach out to our team to discuss your specific needs. We look forward to welcoming you to the Credence community!'
  },
]

export const marketCommoditiesData = [
  {
    sellerName: 'Cameron',
    commodity: 'Rice',
    orderDate: '21/05/2023',
    sellingPrice: '$20',
  },
  {
    sellerName: 'Brooklyn',
    commodity: 'Beans',
    orderDate: '23/06/2023',
    sellingPrice: '$300',
  },
  {
    sellerName: 'Jenny',
    commodity: 'Livestock',
    orderDate: '12/02/2022',
    sellingPrice: '$450',
  },
  {
    sellerName: 'Robert Fox',
    commodity: 'Garri',
    orderDate: '24/02/2022 ',
    sellingPrice: '$125',
  },
  {
    sellerName: 'Jacob Jones',
    commodity: 'Plantain',
    orderDate: '17/08/2022',
    sellingPrice: '$230',
  },
  {
    sellerName: 'Cameron',
    commodity: 'Rice',
    orderDate: '21/05/2023',
    sellingPrice: '$20',
  },
  {
    sellerName: 'Jenny',
    commodity: 'Livestock',
    orderDate: '12/02/2022',
    sellingPrice: '$450',
  },
  {
    sellerName: 'Jacob Jones',
    commodity: 'Plantain',
    orderDate: '17/08/2022',
    sellingPrice: '$230',
  },
  {
    sellerName: 'Brooklyn',
    commodity: 'Beans',
    orderDate: '23/06/2023',
    sellingPrice: '$300',
  },
  {
    sellerName: 'Jenny',
    commodity: 'Livestock',
    orderDate: '12/02/2022',
    sellingPrice: '$450',
  },
  {
    sellerName: 'Robert Fox',
    commodity: 'Garri',
    orderDate: '24/02/2022 ',
    sellingPrice: '$125',
  },
  {
    sellerName: 'Jacob Jones',
    commodity: 'Plantain',
    orderDate: '17/08/2022',
    sellingPrice: '$230',
  },
  {
    sellerName: 'Jenny',
    commodity: 'Livestock',
    orderDate: '12/02/2022',
    sellingPrice: '$450',
  },
  {
    sellerName: 'Jacob Jones',
    commodity: 'Plantain',
    orderDate: '17/08/2022',
    sellingPrice: '$230',
  },
  {
    sellerName: 'Brooklyn',
    commodity: 'Beans',
    orderDate: '23/06/2023',
    sellingPrice: '$300',
  },
  {
    sellerName: 'Jenny',
    commodity: 'Livestock',
    orderDate: '12/02/2022',
    sellingPrice: '$450',
  },
]

export const commodities = [
  {
    commodity: 'Foreign Rice',
    company: 'Randell Group',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Foreign Rice',
    company: 'Randell Group',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Foreign Rice',
    company: 'Randell Group',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Foreign Rice',
    company: 'Randell Group',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Foreign Rice',
    company: 'Randell Group',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Seed Oils',
    company: 'Aminat',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Seed Oils',
    company: 'Aminat',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Seed Oils',
    company: 'Aminat',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
  {
    commodity: 'Seed Oils',
    company: 'Aminat',
    location: 'Abuja',
    duration: 'Jan 14 -  Jan 28',
    price: 'NGN 240,000/Mt',
    icon: AiOutlineTag
  },
]
