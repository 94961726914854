import Footer from "landing-page/components/Footer";
import Navbar from "landing-page/components/Navbar";
import React from "react";

function Policy() {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <div className="policy p-4">
          <h2 className="text-base text-textDark font-thicker my-3">
            Privacy Policy
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            This section outlines our policies regarding the gathering,
            utilization, disclosure, deletion, and restriction of Personal Data
            when you engage with the Credence platform and how we uphold the
            integrity and security of your personal information.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Rest assured, we will not utilize or disclose your information to
            any party other than what is explicitly described in this Privacy
            Policy.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Your Personal Data is utilized to enhance and facilitate the
            provision of services on the Credence platform. By using our
            platform, you implicitly consent to the collection and utilization
            of information in alignment with this policy. Unless specifically
            defined otherwise in this Privacy Policy, the terms used herein
            carry the same meanings as outlined in our terms and conditions.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Who are We
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Credence is a platform focused on Farmers and Agri-SMEs, bridging
            agritech (Market Access) and fintech (Credit and Escrow Services).
            Our digital platform is designed to enhance productivity,
            efficiency, and income, fostering an enabling environment for
            agribusiness at scale in Nigeria.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We aim to provide increased access to a diverse array of buyers,
            improved prices, and enhanced efficiency throughout the agri-food
            supply chain, both upstream and downstream of the farmer. Our goal
            is to elevate the cash flow of Smallholder Farmers (SHFs) and
            agri-SMEs by offering more accessible options, streamlined payments,
            and efficient product distribution.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Information Gathering and Utilization
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            As you engage with our Credence platform, you may be prompted to
            furnish specific personally identifiable information, which serves
            the purpose of contacting or identifying you. This may encompass,
            but is not restricted to, details such as your email address, name,
            phone number, postal address, date of birth, photograph, bank
            details, and usage data ("Personal Data").
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In certain instances, Credence may find it necessary to collect
            sensitive information about you.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Moreover, here's an insight into the Personal Data we collect and
            the methods employed:
          </p>
          <blockquote className="my-4 p-6">
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              1. <b>Basic Personal Information:</b> When you establish an
              account on our platform, we gather essential details like your
              name, email address, and an authentication method (such as a
              password). Additionally, you have the option to provide
              supplementary information, including your phone number, location,
              a profile picture, address, and payment account details.
              Information may also be received from other Credence Users and
              third-party payment processing providers like PayStack.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              2. <b>Transaction Activity:</b> We keep a record of information
              related to deposits into your account and the loans you facilitate
              through the platform. This includes individual details such as the
              amount and recipient of a loan, as well as aggregate information
              like the number of loans made and distribution by gender, country,
              and sector.
            </p>
          </blockquote>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Rest assured, Credence values your privacy and handles your data
            with the utmost care and security.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Logging Information
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            When you access the Credence platform via a mobile device, certain
            information may be automatically collected, including, but not
            limited to, the type of mobile device you are using, your mobile
            device's unique ID, the IP address associated with your mobile
            device, your mobile operating system, the type of mobile internet
            browser you use, and other statistical data ("Log Data").
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Moreover, we may employ third-party platforms like Google Analytics
            to collect, monitor, and analyze this information with the aim of
            enhancing the functionality of our platform. It's essential to note
            that these third-party platform providers operate under their own
            privacy policies, governing how they utilize such information.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Location Details
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            With your permission, we may utilize and store information about
            your location. This information is instrumental in providing
            specific features on our platform and is used to enhance and
            customize your experience. You have the flexibility to enable or
            disable location features at any time through your mobile device
            settings when using our Credence platform.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">Cookies</h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In the context of utilizing the Credence Marketplace Platform,
            certain elements of our platform may employ "cookies" — files
            embedded in your hard drive by websites to store information
            pertaining to your web usage patterns. These cookies are
            instrumental in enhancing and personalizing your experience on our
            Marketplace Platform. They enable the collection of information such
            as:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            - Browser type <br />
            - Time spent on the site <br />
            - Pages visited <br />
            -Language preferences
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Additionally, these cookies may be utilized to gather and store
            information about your usage of specific components of our
            Marketplace Platform. In such cases, we or our service providers may
            collect anonymous information that allows us to analyze details
            about the date, time, and duration of visits to our platform
            components without personally identifying you.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Pixel tags, web beacons, clear GIFs, or similar technologies may be
            employed in connection with our Marketplace Platform to track your
            usage patterns and compile statistics regarding site usage and
            response rates. Typically, this information is automatically
            transmitted to us by your web browser.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Our use of cookies is aimed at:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            - Improving the functionality of our site <br />
            - Facilitating the processing of your purchase orders <br />
            - Enhancing the display of information <br />
            - Personalizing your overall experience on our site <br />-
            Gathering insights into how our site is used and compiling anonymous
            and aggregate statistics
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            While you have the option to decline cookies by disabling them in
            your browser settings, it's important to note that doing so may
            result in some difficulty in using certain components of our
            Credence Marketplace Platform.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Data Usage Purposes:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            For Credence Platform:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In our commitment to providing you with top-notch services on the
            Credence platform, we may utilize the information we collect about
            you in the following ways:
          </p>
          <blockquote className="my-4 p-6">
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              1. <b>Contractual Obligations:</b> To fulfill our obligations
              arising from any contracts entered into between you and us,
              delivering the information, products, and services you request.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              2. <b>Tailored Offerings:</b> To provide information about goods
              and services similar to those you have purchased or inquired
              about, enhancing your overall experience.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              3. <b>Marketing Communications:</b> To offer you information about
              goods or services that may interest you. For existing customers,
              communication will be via electronic means (e.g., email, SMS, or
              WhatsApp) based on past purchases. New customers will be contacted
              electronically only if they consent.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              4. <b>Site Enhancement:</b> To ensure our site's content is
              presented effectively for you and to notify you about changes to
              our platform.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              5. <b>Internal Operations:</b> For site administration,
              troubleshooting, data analysis, testing, research, development,
              statistical and survey purposes.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              6. <b>Advertising Effectiveness:</b> To measure and understand the
              effectiveness of the advertising we serve to you and others,
              delivering relevant advertising and personalized suggestions.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              7. <b>Legal Compliance:</b> For compliance with legal obligations,
              tasks carried out in the public interest, protection of vital
              interests, and as part of our legitimate interest to prevent
              fraud, verify identity, and comply with applicable laws.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              8. <b>Fraud Prevention:</b> To carry out fraud prevention checks,
              including the use of fraud prevention databases, with details
              shared in cases of false information or identified fraud.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              9. <b>Service Optimization:</b> To deliver the best service and
              solutions, including advertising, based on preferences,
              development of new functionalities and services, and provision of
              location-based services.
            </p>
          </blockquote>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Whenever we use your information for legitimate interests, we ensure
            pseudonymized processing at aggregated levels to maintain privacy.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            For Personal Data Collection:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The primary purposes for collecting your Personal Data include:
          </p>
          <blockquote className="my-4 p-6">
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              1. <b> Marketplace Activities:</b> Facilitating bids, offers,
              purchase orders, prices, and crop sampling.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              2. <b>Identity Verification:</b> Ensuring a secure and trustworthy
              environment.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              3. <b> Relationship Maintenance:</b> Responding to queries,
              maintaining relationships, providing products and services,
              sending statements and invoices, and processing payments.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              4. <b>Information Updates:</b> Keeping you informed about changes
              to our Marketplace Platform terms, conditions, and policies.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              5. <b>Research and Communication:</b> Conducting internal research
              and communicating about products, services, initiatives, and
              updates.
            </p>
          </blockquote>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            General Data Usage:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We collect and use your personal data for various purposes:
          </p>
          <blockquote className="my-4 p-6">
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              1. <b>Contractual Terms:</b> To fulfill the terms of contracts,
              including providing services per our Terms of Use.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              2. <b>Consent:</b> Where you have given consent.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              3. <b>Legitimate Interests:</b> To conduct operations, such as
              facilitating access, personalizing experiences, communicating
              about transactions, generating leads, analysing and improving
              services, and complying with legal obligations.
            </p>
          </blockquote>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Please note that failure to provide necessary information may impact
            our ability to deliver services or open/manage your Credence
            account.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            If you have any objections, we respect your right to object to
            processing based on legitimate interests, understanding that it may
            affect our ability to provide certain services for your benefit.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Data Disclosure Policy:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            You acknowledge and agree that we have the right to share your
            personal data:
          </p>
          <blockquote className="my-4 p-6">
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              1. <b> Affiliated Companies and Platform Providers:</b> With any
              affiliated companies and platform providers associated with
              Credence.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              2. <b>Financial Platform Providers:</b> With financial platform
              providers, including those financial institutions outlined in your
              cardholder bank agreement, which provide banking platforms related
              to your account.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              3. <b> Non-Financial Companies:</b> With non-financial companies,
              such as email platform providers for marketing services on our
              behalf and fraud prevention platform providers supporting our
              operations.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              4. <b>Third-Party Access:</b> With non-affiliated third parties
              for accessing and transmitting your personal and financial
              information from relevant financial institutions. You grant these
              third parties the right, power, and authority to access and
              transmit information according to the terms of their privacy
              policies.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              5. <b>Business Partners and Third Parties:</b> With selected third
              parties, including business partners, suppliers, and
              subcontractors, for the performance of any contract entered into
              with them or you.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              6. <b>Business Transactions:</b> With third parties in case of
              selling, transferring, merging parts of our business or assets, or
              acquiring all or parts of their businesses.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              7. <b>Legal Compliance:</b> In response to a request for
              information if required by applicable law, regulation, or legal
              process, and with relevant law enforcement officials or other
              third parties if we believe it is appropriate to investigate
              fraud.
            </p>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Credit Information Usage at Credence:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In the context of your involvement in the Credence Marketplace,
            credit information obtained from you or a credit reporting body,
            along with derived data, is strictly utilized for the sole purpose
            of evaluating your credit application. If you choose not to
            authorize us to access your credit information, it may hinder our
            ability to assess your creditworthiness and, consequently, your
            suitability for prepayment under our model agreement. In such
            instances, there is a possibility that your credit request may be
            declined.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In accordance with applicable circumstances for your participation
            in the Credence Marketplace, we may, when permitted by law, disclose
            your Personal Data to credit reporting bodies. This disclosure is
            particularly relevant in situations where there is a failure to meet
            payment obligations concerning consumer credit. The information
            shared with credit reporting bodies may be included in reports
            provided to other credit providers, aiding them in evaluating your
            creditworthiness.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Service Providers
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            At Credence, we may enlist the services of third-party companies and
            individuals to support and facilitate our platform. These entities
            are engaged to provide platform-related functions, assist in the
            analysis of platform usage, or deliver the platform on our behalf.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            It's essential to note that these third parties are granted access
            to your personal data solely for the purpose of executing tasks on
            our behalf. They are bound by an obligation not to disclose or
            utilize your personal data for any other purpose. Your privacy and
            data security are paramount, and we ensure that these service
            providers adhere to stringent confidentiality standards.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Communications at Credence:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Your personal data may be utilized to reach out to you with
            newsletters, marketing, promotional materials, and other information
            that could be of interest to you. If you prefer not to receive any
            or all of these communications from us, you have the option to opt
            out by following the unsubscribe link or instructions provided in
            any email we send.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Compliance with Laws
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In instances where we are legally obligated to do so by law or
            subpoena, or when we believe such action is necessary to comply with
            the law, meet the reasonable requests of law enforcement, or
            safeguard the security and integrity of our platform, we will
            disclose your personal data.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Security Measures for Your Personal Information
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            At Credence, we implement reasonable measures to uphold proper
            physical, technical, and administrative security protocols, aiming
            to prevent loss, misuse, unauthorized access, disclosure, or
            modification of personal information. Despite these diligent efforts
            to safeguard your personal information, it's essential to
            acknowledge that no system or data transmission over the Internet or
            any other public network can be guaranteed to be 100% secure. Your
            privacy and data security remain priorities, and we continuously
            strive to enhance our protective measures.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Links to Other Sites:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Credence's platform may include links to external sites not operated
            by us. Clicking on a third-party link will redirect you to that
            specific site. We strongly recommend reviewing the privacy policy of
            each site you visit.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We want to emphasize that we have no control over and assume no
            responsibility for the content, privacy policies, or practices of
            any third-party sites or platforms.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Children's Privacy:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Our platform is not intended for individuals under the age of 18
            ("Children").
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We do not knowingly collect personally identifiable information from
            children under 18. If you are a parent or guardian and are aware
            that your child has provided us with personal information, please
            contact us. Upon becoming aware that we have collected personal
            information from a child under age 18 without verification of
            parental consent, we take necessary steps to remove that information
            from our servers.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Right to Information:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Be informed about how we collect, use, and safeguard your data
                for clear and transparent information about our data practices.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Access Request:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Request access to your personal data, commonly known as a "data
                subject access request," enabling you to receive a copy of the
                personal data we hold about you. Information and actions taken
                in response to your request will be provided free of charge
                unless manifestly unfounded or excessive.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Data Correction:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Request correction of your personal data to rectify any
                incomplete or inaccurate information we hold about you, with
                verification of the accuracy of new data provided.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Data Erasure:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Request erasure of your personal data, allowing you to ask us to
                delete or remove data where there's no lawful reason for
                continued processing, or where you've successfully exercised
                your right to object.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Objection to Processing:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Object to processing of your personal data based on legitimate
                interests, particularly if there's an impact on your fundamental
                rights. You also have the right to object to processing for
                direct marketing purposes.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Processing Restriction:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Request restriction of processing in scenarios such as
                establishing data accuracy, where data use is unlawful but you
                want to retain it, and when verifying if we have legitimate
                grounds to use your data despite your objection.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Data Transfer:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Request the transfer of your personal data to you or a third
                party in a structured, commonly used, machine-readable format,
                applicable to automated information you initially provided
                consent for or where information was used to perform a contract.
              </li>
            </ul>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            Withdrawal of Consent:
          </h2>
          <blockquote className="my-4 p-6">
            <ul className="text-sm font-extralight text-textDark leading-7 my-3">
              <li className="list-disc">
                Withdraw consent at any time when processing is based on
                consent. Withdrawal doesn't affect the lawfulness of prior
                processing, but we'll inform you if certain products or
                platforms can't be provided without your consent.
              </li>
            </ul>
          </blockquote>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Credence is committed to upholding your rights in accordance with
            applicable regulations.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Revisions to Our Privacy Policy:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Our Privacy Policy may undergo updates periodically. Notification of
            any changes will be made by posting the updated Privacy Policy on
            this page.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We recommend reviewing this Privacy Policy periodically for any
            modifications. Changes become effective upon posting on this page.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            Contact Us:
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            If you have any inquiries about our Privacy Policy, Please send us
            an email at support@credenceapp.co
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Policy;
