import Footer from "landing-page/components/Footer";
import Navbar from "landing-page/components/Navbar";
import HashElement from "landing-page/hash-element/HashElement";
import React from "react";

function Terms() {
  return (
    <div>
      <Navbar />
      <div className="container mx-auto">
        <div className="terms p-4">
          <h2 className="text-base text-textDark font-thicker my-3">
            Terms and Condition
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            This document pertains to the use of the Credence transaction
            platform (www.credenceapp.co), also referred to as "The Platform".
            By accessing and using this website, including all of its pages, you
            agree to be bound by the following Website Terms and Conditions
            ("Terms"). Credence grants you a non-exclusive license to access and
            use the Services (defined below), subject to these Terms. Throughout
            these Terms, "we", "us", and "our" refer to Credence, and "you" and
            "your" refer to the user of the Platform, also known as our customer
            or "Trader". By using this Website, you acknowledge and agree to all
            the terms and conditions outlined in this document. If you have any
            objections to any of these Terms, you must not use this Website.
            Please read and understand the Terms of Agreement carefully before
            agreeing to be bound by them.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            These Terms apply in full force and effect to your use of this
            Website and by using this Website, you expressly accept all terms
            and conditions contained herein in full. You must not use this
            Website, if you have any objection to any of these Terms.
          </p>
          <p className="text-sm font-thick text-textDark leading-7 my-3">
            PLEASE READ AND UNDERSTAND THE TERMS OF AGREEMENT CAREFULLY BEFORE
            BEING AGREED TO BE BOUND BY ITS TERMS.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            2. PLATFORM DESCRIPTION
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The Platform is a platform focused on Farmers and Agri-SMEs,
            bridging agritech (Market Access) and fintech (Credit and Escrow
            Services). Our digital platform is designed to enhance productivity,
            efficiency, and income, fostering an enabling environment for
            agribusiness at scale in Nigeria.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We aim to provide increased access to a diverse array of buyers,
            improved prices, and enhanced efficiency throughout the agri-food
            supply chain, both upstream and downstream of the farmer. Our goal
            is to elevate the cash flow of Smallholder Farmers (SHFs) and
            agri-SMEs by offering more accessible options, streamlined payments,
            and efficient product distribution.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            3. ACCOUNT REGISTRATION AND AGE RESTRICTION
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In order to use the Platform, you must register for an account. By
            registering for an account, you represent and warrant that you are
            at least 18 years of age and have the legal capacity to enter into
            these Terms. When registering for an account, you will be required
            to provide accurate, complete and current information. You are
            responsible for maintaining the confidentiality of your account and
            password, and for restricting access to your account. You agree to
            accept responsibility for all activities that occur under your
            account.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            4. USE OF THE PLATFORM
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The Platform is intended for use only by buyers and sellers of
            agribusiness commodities. You agree to use the Platform only for its
            intended purpose, and in compliance with these Terms and all
            applicable laws and regulations.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            5. Funds in Escrow
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Once a transaction is initiated on the Platform, the buyer must
            deposit the full payment amount into the escrow account held by a
            regulated bank (“Service Provider”) selected by CredenceApp Africa
            Limited. The funds will be held in the escrow account until the
            transaction is completed or canceled, in accordance with these
            Terms.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            6. Language
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            All communication, regardless of the purpose, must be conducted in
            the English language. If any party needs interpretation from or to
            the English language in order to make or receive any communication
            regarding these Terms and Conditions or the Terms of Use, they will
            be responsible for their own respective costs associated with such
            interpretation.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            7. Credence Marketplace Transactions.{" "}
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The online platform, consisting of the Website, Systems, and
            Services, facilitates various interactions within the Credence
            Marketplace. These interactions include notifying Sellers, and
            distributors of offers to purchase crops, enabling commodity sellers
            to initiate bids for selling crops, notifying buyers of offers to
            sell crops, and allowing buyers to make bids for purchasing crops.
            Credence emphasizes that it does not have control over user conduct
            on the Website, Systems, or Services and disclaims liability to the
            fullest extent permitted by law.
          </p>
          <blockquote className="my-4 p-6">
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              a) Seller-Specific Terms; This section outlines provisions
              applicable to sellers participating in the Credence Marketplace.
              Credence may present a bid to purchase from sellers (referred to
              as a "Sellers"), or sellers may submit an offer to sell to
              Credence (referred to as a "Sellers Offer"). The terms of each
              Sellers Bid or Grower Offer, including price, quality
              specifications, and additional services, will be specified in the
              Credence Marketplace form. Notably, a Sellers Bid remains open
              until Credence issues a cancellation, and a Seller Offer remains
              open until the seller revokes it or it expires. It clarifies that
              Credence can cancel a seller Bid before formal acceptance. Once a
              Grower Offer is accepted by Credence, it becomes irrevocable, and
              successful acceptances are confirmed in a binding written
              confirmation. Credence stresses that no transaction is binding
              until the issuance of a written confirmation, left to its sole
              discretion.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              b) Buyer-Specific Terms; This section outlines provisions for
              buyers participating in the Credence Marketplace. Buyers may
              submit a bid to purchase from Credence (referred to as a "Buyer
              Bid"), or Credence may present an offer to sell to buyers
              (referred to as a "Buyer Offer"). Additional terms, such as price
              and quality specifications, will be included in the Buyer Bid or
              Buyer Offer on the Credence Marketplace form. Buyer Bids remain
              open for a specific period, and once placed, buyers commit to
              purchasing any quantity meeting the terms during this Bid Period.
              Credence has full discretion to accept or reject Buyer Bids. Buyer
              Offers remain open until Credence issues a cancellation. Similar
              to sellers, buyers can cancel an offer before formal acceptance.
              Successful acceptances result in a binding written confirmation,
              emphasizing that no transaction is binding until confirmed in
              writing, subject to Credence's discretion.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              c) Cancellations; Suspensions. Credence retains the sole
              discretion to cancel or terminate Grower Offers, Grower Bids,
              Buyer Offers, or Buyer Bids based on various factors, aiming to
              ensure the integrity of the Credence Marketplace and the
              associated platform.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              d) Crop Sampling; Sellers in the Credence Marketplace commit to
              providing crop samples upon Credence's request for quality
              testing. Following testing, sellers agree to make best efforts to
              segregate the tested crop portion and inform Credence of any
              disposal or commingling. Credence may share test results, and
              sellers are reminded not to rely on such information for anything
              beyond indicative purposes.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              e) Updates; Credence holds the discretion to deploy unscheduled
              changes, updates, or enhancements to the Marketplace Platform,
              including adding or removing functionality. However, there is no
              obligation on Credence's part to provide updates.
            </p>
            <p className="text-sm font-extralight text-textDark leading-7 my-3">
              f) Violations; Credence reserves the right to prevent user access
              to the Marketplace Platform or block communication via SMS or
              other means, at its sole discretion and without specifying a
              reason. Such actions may be taken if Credence deems a user to have
              violated any provision of the Terms of Use.
            </p>
          </blockquote>
          <h2 className="text-base text-textDark font-thicker my-3">
            8) Setting Up Payment Transactions
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            When setting up a payment transaction on the Platform, the buyer
            must provide accurate and complete information regarding the
            transaction, including the type, quantity, quality and price of the
            commodities, and any other terms and conditions agreed upon by the
            parties.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            9) Cancellation of Transaction In place of Escrow
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            If either party wishes to cancel a transaction, they must notify
            Credence immediately. Credence will then review the transaction and
            determine whether to cancel the transaction and release the funds
            back to the buyer, or to continue with the transaction.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            10) Consumer Protection
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Credence takes consumer protection seriously, and has implemented
            various measures to ensure the safety and security of buyers and
            sellers on the Platform.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            These measures include:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Identity verification: Buyers and sellers must provide accurate and
            complete information to verify their identities.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Escrow: Funds are held in escrow until the transaction is completed
            or cancelled, ensuring that the buyer receives the commodities, and
            the seller receives the payment.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Dispute resolution: Credence provides dispute resolution services to
            help resolve any disputes that may arise between buyers and sellers.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            11) Rights And Privileges Of Users
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            As a user of the Platform, you have certain rights and privileges,
            including:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Access to the Platform and its features,Confidentiality of your
            personal information, subject to our Privacy Policy. Prompt and fair
            resolution of any disputes that may arise.
          </p>
          <h2 className="text-base text-textDark font-thicker my-3">
            12) Payments Refund
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In the event of a cancelled transaction or a partial refund to the
            Paying Party, the Paying Party’s funds will be refunded back to the
            source of the payment method used to fund the transaction. The
            transaction fee is non-refundable.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            13) Liabilities And Liability for Invalidated Payments
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The platform shall not be held liable for any direct or indirect
            loss, damage or liability arising out of or in connection with any
            invalidated payment transaction, including but not limited to loss
            of profits, reputation, or any other consequential or incidental
            damages.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            In the event that a payment transaction is invalidated, the platform
            shall not be held liable for any loss or damage arising out of or in
            connection with such invalidation, including but not limited to any
            loss of funds, commodities, or any other consequential or incidental
            damages. The platform shall not be responsible for any losses that
            may be incurred by users as a result of invalidation of a payment
            transaction.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The platform reserves the right to withhold or cancel any payment
            transaction, in its sole discretion, if it suspects that the
            transaction is fraudulent or violates these Terms and Conditions.
            The platform shall not be held liable for any damages arising out of
            or in connection with the withholding or cancellation of a payment
            transaction. The platform shall not be held liable for any losses
            incurred by a user as a result of the user’s failure to comply with
            these Terms and Conditions, including but not limited to failure to
            provide accurate and complete information, failure to comply with
            payment instructions, or failure to comply with any other
            requirements set forth in these Terms and Conditions.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            14) Dispute Resolution
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            To ensure fair and efficient dispute resolution for transactions
            involving agro commodity trading on our escrow platform, the
            following terms and conditions will apply:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Dispute Resolution Procedure:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            a) When initiating a transaction on the escrow platform, users will
            be required to choose a dispute resolution procedure. This procedure
            will come into effect if a dispute arises during the transaction.
            The transaction funds will remain in the escrow account until the
            dispute is mutually resolved between the transacting parties.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Mutual Resolution:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            b) If the Mutual Resolution option is selected by the user when
            initiating a transaction and a dispute arises, the transaction will
            be in a state of "In Dispute" until both parties have come to an
            agreement over the resolution. Once the parties have marked the
            transaction as completed or have mutually agreed to cancel the
            transaction, the funds will be released to the paying party. While
            the transaction is in the "In Dispute" state, the transaction funds
            will remain securely held in the transactional escrow account.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Third-Party Resolution:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            c) Third-party alternative dispute resolution procedures may be
            required for certain transactions above a stated value threshold. If
            users agree to this option when initiating a transaction and a
            dispute arises, the details of the transaction will be forwarded to
            a neutral third party recommended or selected by the escrow
            platform. Such neutral third parties include alternate dispute
            resolution officials licensed by professional bodies in Nigeria such
            as the Lagos Court of Arbitration (LCA) and the Institute of
            Chartered Mediators. The mediator's fees will be borne by the
            transacting parties and are negotiated between the parties and the
            mediator at their discretion. Once an agreement has been reached by
            the parties, the Terms of Settlement are reduced into writing, and
            this constitutes a binding and enforceable contract. The transaction
            funds will be released according to this decision, after which the
            transaction is closed. While the transaction is in the "In Dispute"
            state, the transaction funds will remain securely held in the
            transactional escrow account.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Dispute Initiation:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            d) Only the buyer ("Buyer") may start a transaction dispute
            ("Dispute") in the event that funds remain in the escrow account and
            the Buyer believes that the Seller has failed to comply with their
            obligations in relation to the transaction. To initiate a dispute,
            the Buyer must lodge a complaint within the escrow platform and
            provide evidence, including any business terms, quote, images or
            video relative to the transaction, proof of recorded or courier
            delivery, and/or any correspondence or other documentation relative
            to the transaction. The seller will be notified that the dispute has
            been initiated, and it will be visible on the status page of that
            transaction. The date on which such dispute is logged is called the
            "Dispute Date". The Buyer must then follow the dispute process as
            indicated within the escrow platform.
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            Arbitration:
          </p>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            e) If a dispute isn't resolved after the complaints process has
            ended, an arbitration period will begin ("Arbitration Commencement
            Period"). The escrow platform reserves the right to arbitrate and
            decide which party is liable for the dispute and to refund whoever
            the platform feels was wronged, less the platform's fees. For more
            complex cases, the parties agree to refer disputes and differences
            of any kind to the Dispute Resolution Rules, which shall be read and
            construed as if set out in full herein. If the escrow platform has
            received notice that either party is not in full compliance with the
            arbitration process, the platform may terminate or cancel the
            transaction and refund whoever the platform feels was wronged, less
            the platform's fees. The platform reserves the right (but is under
            no legal obligation) to commence the arbitration on behalf of the
            parties or to interplead the funds with a court of competent
            jurisdiction at any time. In this case, both parties authorize the
            platform to use the funds to pay the arbitration fees
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            15) Disclaimer And Limitation
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The platform provides its services “as is” and “as available,”
            without any warranties or representations, express or implied,
            including but not limited to warranties of merchantability, fitness
            for a particular purpose, and non-infringement. The platform does
            not guarantee the accuracy, completeness, timeliness, or reliability
            of any information or data on the platform. The platform shall not
            be liable for any damages arising out of or in connection with the
            use or inability to use the platform, including but not limited to
            any direct, indirect, incidental, consequential, or punitive
            damages.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            16) Notices and Communication
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            All notices and other communications required or permitted to be
            given under these Terms and Conditions shall be in writing and shall
            be delivered by hand, by registered or certified mail, or by email
            to the address provided by the user during registration.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            17) Indemnity
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            The user shall indemnify and hold the platform harmless from any and
            all claims, damages, liabilities, costs, and expenses (including
            reasonable attorneys’ fees) arising out of or in connection with the
            user’s use of the platform, any breach of these Terms and Conditions
            by the user, or any violation of any law or regulation by the user.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            18) GOVERNING LAW AND JURISDICTION
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of Nigeria. Any dispute arising out of or
            in connection with these Terms and Conditions shall be submitted to
            the exclusive jurisdiction of the courts of Nigeria. These Terms and
            Conditions, together with any additional terms and conditions or
            policies posted on the platform, constitute the entire agreement
            between the user and the platform with respect to the use of the
            platform and supersede all prior or contemporaneous communications
            and proposals, whether oral or written, between the user and the
            platform.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            19) Privacy Policy & Cookie Policy
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            By using the website, you agree to comply with our Privacy Policy
            and Cookie Policy, and you agree not to engage in any activities
            that may breach our compliance with these policies or act contrary
            to them. You acknowledge that we reserve the right to make changes
            to our Privacy Policy and Cookie Policy at any time and without
            prior notice. Your continued use of the website following any
            modifications to the policies indicates your acceptance of such
            changes.
          </p>
          <h2 className="text-sm font-thicker text-textDark leading-7 my-3">
            20) Force Majeure
          </h2>
          <p className="text-sm font-extralight text-textDark leading-7 my-3">
            We shall not be held responsible for any failure or delay in the
            performance of our obligations under this agreement, where such
            failure or delay is caused by circumstances beyond our control, such
            as acts of God, strikes, war, terrorism, government regulations, or
            any other unforeseeable and unavoidable event (collectively referred
            to as "Force Majeure Event"). In the event of a Force Majeure Event,
            we shall make all reasonable efforts to mitigate the impact of such
            an event on the escrow service. However, if the Force Majeure Event
            continues for a period of more than 30 days, either party may
            terminate this agreement without any liability to the other party.
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Terms;
